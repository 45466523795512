var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["icon-holder", _vm.name],
      style: { width: _vm.size + "px", height: _vm.size + "px" },
      on: { click: _vm.clickHandler },
    },
    [
      _c(
        "svg",
        {
          staticStyle: { "fill-rule": "evenodd", "clip-rule": "evenodd" },
          attrs: {
            preserveAspectRatio: "xMidYMid",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: `0 0 25 25`,
            width: _vm.size + "px",
            height: _vm.size + "px",
          },
        },
        [
          _c("path", {
            class: _vm.color == null ? "icon-" + _vm.variant : "",
            attrs: {
              d: _vm.path,
              fill: _vm.color,
              stroke: _vm.stroke,
              "stroke-width": _vm.strokeWidth,
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }