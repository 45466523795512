<template>
  <div
    @click="clickHandler"
    :class="['icon-holder', name]"
    :style="{ width: size + 'px', height: size + 'px' }"
  >
    <svg
      preserveAspectRatio="xMidYMid"
      xmlns="http://www.w3.org/2000/svg"
      :viewBox="`0 0 25 25`"
      :width="size + 'px'"
      :height="size + 'px'"
      style="fill-rule: evenodd; clip-rule: evenodd"
    >
      <!--  :transform="`translate(${size / (25 / 2)}, ${size / (25 / 2)})`"  -->
      <path
        :d="path"
        :fill="color"
        :class="color == null ? 'icon-' + variant : ''"
        :stroke="stroke"
        :stroke-width="strokeWidth"
      />
    </svg>
  </div>
</template>

<script>
import icon from "./index";
export default {
  mixins: [icon],
  props: {
    name: String,
    size: {
      type: String,
      default: "25",
    },
    color: {
      type: String,
    },
    stroke: {
      type: String,
    },
    strokeWidth: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: "primary",
      validator(x) {
        return [
          "primary",
          "secondary",
          "warning",
          "dark",
          "light",
          "info",
          "danger",
          "success",
          "tertiary",
        ].includes(x);
      },
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-primary {
  fill: $primary;
}

.icon-secondary {
  fill: $secondary;
}

.icon-warning {
  fill: $warning;
}

.icon-info {
  fill: $info;
}

.icon-dark {
  fill: $text-color;
}

.icon-light {
  fill: $light;
}

.icon-danger {
  fill: $danger;
}

.icon-success {
  fill: $success;
}

.icon-tertiary {
  fill: $tertiary;
}

.icon-holder {
  position: relative;
  outline: 0;
}
</style>
